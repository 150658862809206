var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.updateTaskStatus },
      }),
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowed },
        on: { close: _vm.closeNotAllowed },
      }),
      this.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mx-auto mt-3" },
                [
                  _c(
                    "v-container",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1 ",
                        attrs: {
                          items: _vm.task,
                          search: _vm.searchResult,
                          headers: _vm.headersTask,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.created_at",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.convertTimeZone(item.created_at)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.role_id",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  item.role_id != null
                                    ? _c("span", [_vm._v(" Team ")])
                                    : _vm._e(),
                                  item.user_id != null
                                    ? _c("span", [_vm._v(" Personal ")])
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.task_status",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm.loadingUpdateStatus
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          item.task_status == "Done"
                                            ? _c("v-checkbox", {
                                                staticStyle: { color: "green" },
                                                attrs: { color: "success" },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.validateInput(
                                                      item,
                                                      _vm.statusCheckedTrue
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.statusCheckedTrue,
                                                  callback: function ($$v) {
                                                    _vm.statusCheckedTrue = $$v
                                                  },
                                                  expression:
                                                    "statusCheckedTrue",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                            {
                              key: "item.priority",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  item.priority == "Urgent"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold my-3 red--text",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.priority) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.priority == "Medium"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-bold my-3",
                                          staticStyle: { color: "#FBB955" },
                                        },
                                        [_vm._v(" Mid ")]
                                      )
                                    : _vm._e(),
                                  item.priority == "Low"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold my-3 green-text",
                                        },
                                        [_vm._v(" Low ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.company_name",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  item.company_id != 33
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "PageCompaniesDetail",
                                              params: { id: item.company_id },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.company_name) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [_vm._v(" - ")]),
                                ]
                              },
                            },
                            {
                              key: "item.task_category",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  item.task_category ==
                                    "Client Subscription Renewal" ||
                                  item.task_category == "Client Payment Failed"
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "PageCompaniesDetail",
                                              params: { id: item.company_id },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.task_category) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.task_category) + " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "item.index",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.task.indexOf(item) + 1) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-toolbar",
                                        {
                                          staticClass: "pa-3",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              filled: "",
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              label: "Search",
                                            },
                                            model: {
                                              value: _vm.searchResult,
                                              callback: function ($$v) {
                                                _vm.searchResult = $$v
                                              },
                                              expression: "searchResult",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3739718433
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }