var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mx-auto mt-3" },
                [
                  _c(
                    "v-container",
                    [
                      this.status == "complete"
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              items: _vm.task,
                              headers: _vm.taskHeaderCompleted,
                              search: _vm.searchResult,
                              dense: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.index",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.task.indexOf(item) + 1) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              staticClass: "pa-3",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  filled: "",
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  label: "Search",
                                                },
                                                model: {
                                                  value: _vm.searchResult,
                                                  callback: function ($$v) {
                                                    _vm.searchResult = $$v
                                                  },
                                                  expression: "searchResult",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.task_status",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c("v-checkbox", {
                                            staticStyle: { color: "green" },
                                            attrs: {
                                              color: "success",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.statusCheckedTrue,
                                              callback: function ($$v) {
                                                _vm.statusCheckedTrue = $$v
                                              },
                                              expression: "statusCheckedTrue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3006359092
                            ),
                          })
                        : _vm._e(),
                      this.status == "incomplete"
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              items: _vm.task,
                              headers: _vm.taskHeaderIncompleted,
                              search: _vm.searchResult,
                              dense: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.pic_name",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      item.pic_name != null
                                        ? _c("span", [
                                            _vm._v(
                                              " " + _vm._s(item.pic_name) + " "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.role_name ==
                                                    "vision_admin"
                                                    ? "Admin"
                                                    : item.role_name.replaceAll(
                                                        "_",
                                                        " "
                                                      )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.index",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.task.indexOf(item) + 1) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              staticClass: "pa-3",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  filled: "",
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: "",
                                                  label: "Search",
                                                },
                                                model: {
                                                  value: _vm.searchResult,
                                                  callback: function ($$v) {
                                                    _vm.searchResult = $$v
                                                  },
                                                  expression: "searchResult",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.task_status",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c("v-checkbox", {
                                            staticStyle: { color: "green" },
                                            attrs: {
                                              color: "success",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.statusCheckedFalse,
                                              callback: function ($$v) {
                                                _vm.statusCheckedFalse = $$v
                                              },
                                              expression: "statusCheckedFalse",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3258065736
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }