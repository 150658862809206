var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.mainTab,
                    callback: function ($$v) {
                      _vm.mainTab = $$v
                    },
                    expression: "mainTab",
                  },
                },
                [
                  _c("v-tab", [_vm._v(" My Task ")]),
                  _c("v-tab", [_vm._v(" Task Assigned ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.mainTab,
                callback: function ($$v) {
                  _vm.mainTab = $$v
                },
                expression: "mainTab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { flat: "" },
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              staticClass: "v-tab-element mt-3",
                              attrs: { to: "#incomplete" },
                            },
                            [_vm._v(" Incomplete ")]
                          ),
                          _c(
                            "v-tab",
                            {
                              staticClass: "mt-3",
                              attrs: { outlined: "", to: "#complete" },
                            },
                            [_vm._v(" Completed ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { id: "incomplete" } },
                        [_c("ComponentTaskListUserIncomplete")],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { id: "complete" } },
                        [_c("ComponentTaskListUserComplete")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-tab-item", [
                _c(
                  "div",
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "d-flex justify-center ",
                        attrs: { flat: "" },
                      },
                      [
                        _c(
                          "v-tabs",
                          {
                            model: {
                              value: _vm.tabtwo,
                              callback: function ($$v) {
                                _vm.tabtwo = $$v
                              },
                              expression: "tabtwo",
                            },
                          },
                          [
                            _c("v-tab", [_vm._v(" Incomplete ")]),
                            _c("v-tab", [_vm._v(" Complete ")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.tabtwo,
                          callback: function ($$v) {
                            _vm.tabtwo = $$v
                          },
                          expression: "tabtwo",
                        },
                      },
                      [
                        _c(
                          "v-tab-item",
                          [
                            _c("ComponentTaskAssigned", {
                              attrs: { status: "incomplete" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          [
                            _c("ComponentTaskAssigned", {
                              attrs: { status: "complete" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }