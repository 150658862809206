import { render, staticRenderFns } from "./ComponentTaskListUserIncomplete.vue?vue&type=template&id=16bc4b6f&"
import script from "./ComponentTaskListUserIncomplete.vue?vue&type=script&lang=js&"
export * from "./ComponentTaskListUserIncomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCheckbox,VCol,VContainer,VDataTable,VRow,VSkeletonLoader,VSwitch,VTextField,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16bc4b6f')) {
      api.createRecord('16bc4b6f', component.options)
    } else {
      api.reload('16bc4b6f', component.options)
    }
    module.hot.accept("./ComponentTaskListUserIncomplete.vue?vue&type=template&id=16bc4b6f&", function () {
      api.rerender('16bc4b6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/task/ComponentTaskListUserIncomplete.vue"
export default component.exports