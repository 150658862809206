import { render, staticRenderFns } from "./PageTaskListUser.vue?vue&type=template&id=3c4d489d&scoped=true&"
import script from "./PageTaskListUser.vue?vue&type=script&lang=js&"
export * from "./PageTaskListUser.vue?vue&type=script&lang=js&"
import style0 from "./PageTaskListUser.vue?vue&type=style&index=0&id=3c4d489d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4d489d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VContainer,VTab,VTabItem,VTabs,VTabsItems,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c4d489d')) {
      api.createRecord('3c4d489d', component.options)
    } else {
      api.reload('3c4d489d', component.options)
    }
    module.hot.accept("./PageTaskListUser.vue?vue&type=template&id=3c4d489d&scoped=true&", function () {
      api.rerender('3c4d489d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/task/PageTaskListUser.vue"
export default component.exports